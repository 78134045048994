<template>
    <div style="height: auto; text-align: center">
        <h1 class="thanks-zone">Thanks</h1>
        <p class="content-zone">You're all set.Please come again!</p>
        <p class="sub-content-zone">
            If you are going to enter a new table, please close this window and re-scan the code
        </p>
    </div>
</template>
<style>
.thanks-zone {
    font-size: 90px !important;
    letter-spacing: 2px !important;
    color: #cacaca !important;
    opacity: 0.85;
    font-weight: 300 !important;
    line-height: 1.5;
    margin-top: 40%;
}
.content-zone {
    font-size: 20px;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    color: #8a8a8a;
    line-height: 1.8;
    margin-bottom: 10px;
}
.sub-content-zone {
    font-size: 18px;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    color: #8a8a8a;
    line-height: 1.2;
    margin-bottom: 10px;
    padding: 0 10px;
}
</style>

<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';
export default {
    name: 'thanks',
    data() {
        return {};
    },
    methods: {
        ...mapMutations({
            set_app_title: 'app/APP_SET_APP_TITLE'
        })
    },
    created() {
        this.set_app_title('Thanks');
    }
};
</script>
